.menu {
  background-color: var(--color-green);
  height: 100%;
  padding: 2.56rem 0.875rem 0 2.31rem;
  user-select: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.menuNavbar {
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.signOut {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.signOutIcon {
  background-image: url('../../assets/images/logout.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

.menuItem {
  color: black;
  font-size: 1.32rem;
}

.menuTitle {
  margin-bottom: 1.93rem;
}

.dropdown {
  margin-bottom: 1.93rem;
}

.activeItem {
  color: white;
}

.multiItems {
  display: flex;
  flex-direction: column;
  padding-left: 4.56rem;
}

.dropdownTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.81rem;
  cursor: pointer;
}

.arrow {
  background-image: url('../../assets/images/arrowDown.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 1rem;
  height: 0.57rem;
}

.arrowActive {
  transform: rotateX(180deg);
}

.dropdownMenuItem {
  margin-top: 0.825rem;
}

.dropdownMenuItem:first-child {
  margin-top: 0;
}