.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.statisticsWrapper {
  width: 100%;
  margin-top: 3rem;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex: 1;
}

.statistics {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.statisticRow {
  width: 100%;
  display: flex;
  // align-items: center;
  padding-bottom: 4rem;
}

.statisticTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 9.5rem;
  margin-right: 2rem;
  width: 9rem;
  text-align: center;
  line-height: 1.3;
}

.statisticItems {
  flex-grow: 1;
  display: flex;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.125rem;
  margin-top: 1rem;
  text-align: center;

  & > span:last-child {
    font-weight: bold;
    margin-top: 0.3rem;
  }
}