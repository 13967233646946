.root {
  width: 30%;
  height: 100%;
  overflow-y: auto;
  margin-right: 1rem;
  padding-right: 1rem;
}

.formItem {
  width: 100%;
}

.formItemTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 0.6rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.formItemMain {
  width: 100%;
  background-color: var(--color-gray5);
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  & > div {
    width: calc(50% - 1rem);
  }
}

.formInputWrapper {
  width: 100%;

  & > input {
    width: 100%;
    padding: 1rem 1rem;
    font-size: 1.125rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 0.15rem;
  }
}

.formInputLabel {
  color: white;
  margin-left: 1rem;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  font-weight: bold;
}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem;
  padding: 0 1rem;

  & > button {
    font-size: 1rem;
    height: 3rem;
    width: 8rem;
  }
}

.formItemCreate {
  .clearButton {
    color: var(--color-gray5);
    border-color: var(--color-gray5);
  }
  
  .addButton {
    background-color: var(--color-gray5);
  }

  .formItemMain {
    background-color: var(--color-gray5);
  }
}


.formItemUpdate {
  margin-top: 4rem;
  
  .formItemMain {
    background-color: var(--color-darkblue);
  }
}