.root {
  position: relative;
}

.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125rem;
  cursor: pointer;
  width: 100%;
  height: 3rem;
  background-color: var(--color-whitegray);
  border-radius: 0.375rem;
  overflow: hidden;
  padding: 0 0.31rem 0 1.125rem;
}

.flex {
  display: flex;
}

.date {
  user-select: none;
}

.calendarIcon {
  background-image: url('../../../assets/images/calendar.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.18rem;
  height: 100%;
}

.disabled {
  opacity: 0.7;
  cursor: default;
}

.clear {
  position: absolute;
  top: 50%;
  right: -1.3rem;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  width: 1rem;
  height: 1.1rem;
  background-image: url('../../../assets/images/recycle.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}