.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  min-width: max-content;
  height: 3.62rem;
  color: var(--color-white);
  font-size: 1.25rem;
  font-weight: 800;
  text-align: center;
  border: none;
  border-radius: 1.75rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

// .loader {
//   position: absolute;
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   transform: translate(50% 50%);
//   z-index: 11;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.variant_primary {
  background-color: var(--color-darkblue);
}

.variant_light {
  background-color: transparent;
  border: 2px solid var(--color-darkblue);
  color: var(--color-darkblue);
}

.variant_danger {
  background-color: var(--color-red);
}

.root:disabled {
  background-color: var(--color-whitegray) !important;
  color: var(--color-gray) !important;
  border: none;
  cursor: default;
}

.buttonArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogo__icon {
  width: 15px;
  height: 15px;
  object-position: center;
}

.buttonText {
  display: inline-block;
  margin: 0 10px;
}
