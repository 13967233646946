.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  padding-bottom: 2rem;
}

.line {
  height: 100%;
  width: 1px;
  background-color: black;
}