.root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 7.25rem;
  margin-bottom: 1rem;
}

.formBoard {
  width: 23.75rem;
}

.logo {
  width: 100%;
  height: 7.375rem;
  // background-image: url('../../assets/images/BI_Logo.svg');
  // background-size: cover;
  margin-bottom: 4.375rem;
}

.secondInput {
  margin-top: 2.9rem;
}

.inputLable {
  margin-bottom: 0.44rem;
  margin-left: 0.44rem;
  font-size: 1.125rem;
}

.buttonWrapper {
  margin-top: 4.125rem;
  width: 12.125rem;

  button {
    width: 100%;
  }
}