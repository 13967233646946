.root {
    background-color: var(--color-whitegray);
    border-radius: 0.375rem;
    padding: 0.31rem 0 0 1.125rem;
    height: 3rem;
}


.root div div {
    border: none;
}