.root {
  display: flex;
  flex-direction: column;
  width: calc((100% / 4) - 3.56rem);
  max-width: 20rem;
  margin: 0 1.78rem;


  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid var(--color-gray6);
    font-weight: 700;
  }

  & > div:first-child {
    height: 4rem;
    margin-bottom: 1.31rem;
    background-color: var(--color-gray6);
    font-size: 1.375rem;
  }

  .info {
    background-color: var(--color-gray3);
    height: 10rem;
    font-size: 2rem;
  }
}



