.radioOuter {
  width: 2.75rem;
  height: 2.75rem;
  border: 1px solid var(--color-gray4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.radioInner {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: transparent;
}

.active {
  background-color: black;
}

.disabled {
  opacity: 0.7;
  cursor: default;
}