.cardItem {
  margin-bottom: 0.69rem;
}

.cardItemShort {
  margin: 0.5rem;
  margin-top: 0;
}

.cardItemShort:first-child {
  margin-top: 0.5rem;
}

.casesBody {
  display: flex;
  & > div:nth-child(1) {
    margin-right: 0.3rem;
  }
}

.contentWrapper {
  display: flex;
}

.imageWrapper {
  margin-right: 2rem;
  border-radius: 0.375rem;
  height: 4.62rem;
  width: 3.75rem;
  min-width: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
  }
}

.imageWrapperActive {
  height: 9.18rem;
  width: 6.56rem;
  min-width: 6.56rem;
  border-radius: 1.25rem;
}

.imageWrapperShort {
  margin-right: 1.5rem;
  // border-radius: 0.375rem;
  height: 3rem;
  width: 1.8rem;
  min-width: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
  }
}

.imageWrapperActiveShort {
  height: 5rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 0.375rem;
}

.content > div {
  padding-bottom: 0.7rem;
}

.content > div:last-child {
  margin-bottom: 0;
}

.bold {
  font-weight: 700;
}

.actions {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  padding-right: 2.25rem;
}

.edit {
  background-image: url('../../../../assets/images/edit.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.56rem;
  height: 1.56rem;
  cursor: pointer;
}

.dotsWrapper {
  position: relative;
}

.dots {
  background-image: url('../../../../assets/images/dots.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 0.5rem;
  height: 1.87rem;
  margin-left: 1.48rem;
  cursor: pointer;
}

.dotsDropdown {
  left: unset !important;
  top: 3px !important;
  right: 0 !important;
}

.dotsDropdownList {
  padding: 1rem 0;
  font-size: 1.125rem;
  cursor: default;
}

.dotsDropdownItem {
  padding: 1rem 1.375rem;
  cursor: pointer;
}

.dotsDropdownItem:hover {
  background-color: var(--color-gray5);
  color: white;
}

.actionItem {
  margin-left: 1.48rem;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}

.switcher {
  margin-right: -1rem;
}

.share {
  white-space: nowrap;
}

.draftAction {
  margin-left: 1.48rem;
  font-size: 1.125rem;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
}

.checkbox {
  padding-right: 2rem;
  padding-left: 1rem;
}

.linkSwitcherText {
  margin-right: .5rem;
}

.actionsShort {
  width: 3rem;
}

.contentShort {
  font-size: 1rem;
}

.contentShort > div {
  padding-bottom: 0.4rem;
}

.contentShort > div:last-child {
  margin-bottom: 0;
}

.translations {
  display: flex;
  flex-wrap: wrap;
}

.translationItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 2.5rem;
  padding: 0.4rem 0;

  & > div:first-child {
    display: flex;
    align-items: center;

    & > div:first-child {
      margin-right: 0.5rem;
    }
  }
}

.recycleIcon {
  width: 1.2rem;
  min-width: 1.2rem;
  height: 1.6rem;
  background-image: url('../../../../assets/images/recycle.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 0.3rem;
  cursor: pointer;
}

.patientTitle {
  display: flex;
  align-items: center;
}

.languageIndicator {
  margin-right: 1rem;
  padding: 0.5rem 1.5rem;
  background-color: gray;
  color: white;
  border-radius: 0.375rem;
  font-size: 1.2rem;
}

.approvedEvent{
  color: #46ACA5 !important;
  font-weight: 700;
}

.draftEvent{
  font-weight: 700;
}