.currentQuestion {
  flex-grow: 1;
  // padding-left: 4.125rem;
}

.currentQuestionHeader {
  width: 100%;
  background-color: var(--color-gray7);
  padding: 1.5rem 2.375rem 1.25rem 2.375rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;

  & button {
    width: 12.125rem;
  }
}

.currentQuestionHeader {
  margin-bottom: 1.5rem;
  & > div:first-child {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  & > div:last-child {
    font-size: 1.375rem;
    max-width: 49.3rem;
  }
}