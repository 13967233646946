.root {
  width: 100%;
  height: 3rem;
  background-color: var(--color-whitegray);
  border-radius: 0.375rem;
  overflow: hidden;
  padding: 0 1.125rem;
  position: relative;
}

.eyeRoot {
  padding-right: 2.5rem;
}

.input {
  width: 100%;
  height: 100%;
  background-color: var(--color-whitegray);
  outline: none;
  border: none;
  font-size: 1.125rem;
}

.eye {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translate(0, -50%);
  background-image: url('../../../assets/images/view.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.eyeActive {
  background-image: url('../../../assets/images/hide.svg');
}