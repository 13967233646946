:root {
  --color-whitegray: rgb(229, 229, 229);
  --color-green: rgb(92, 189, 181);
  --color-lightgreen: rgb(149, 219, 201);
  --color-lightgreen3: rgba(149, 219, 201);
  --color-darkblue: rgb(28, 54, 100);
  --color-lightblue: rgb(17, 202, 188);
  --color-gray: rgb(181, 181, 181);
  --color-gray1: rgb(102, 102, 102,);
  --color-gray2: rgb(64, 64, 64);
  --color-gray3: rgb(243, 243, 243) ;
  --color-gray4: rgb(196, 196, 196);
  --color-gray5: rgb(127, 127, 127);
  --color-gray6: rgb(191, 191, 191);
  --color-gray7: rgb(242, 242, 242) ;
  --color-gray8: rgb(233, 233, 233);
  --color-gray9: rgb(183, 183, 183);
  --color-gray10: rgb(143, 143, 143);
  --color-lightgray: rgb(67, 78, 84);
  --color-white: rgb(255, 255, 255);
  --color-white2: rgb(251, 251, 251);
  --color-red: rgb(215, 43, 52);
  --color-red2: rgb(235, 34, 45);
}