.background {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(128, 128, 128, 0.288);
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100vh - 5rem);
  width: 62rem;
  // margin-top: 3.43rem;
  z-index: 11;
  border-radius: 1.56rem;
  // margin-left: 13.3vw;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.noTitleModal {
  border: 2px solid #B7B7B7;
}

.header {
  display: flex;
  align-items: center;
  user-select: none;
  background-color: var(--color-gray4);
  height: 4.93rem;
  min-height: 4.93rem;
  padding-left: 2.125rem;
}

.content {
  flex: 1;
  border: 2px solid #B7B7B7;
  border-radius: 0 0 1.56rem 1.56rem;
  border-top: 0;
  display: flex;
  flex-direction: column;
  padding: 2.62rem 1.75rem 1.31rem 3.5rem;
  overflow: hidden;
}

.noTitleContent {
  border: 0;
}

.contentBody {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 1.18rem;
}


.title {
  font-size: 2rem;
  font-weight: 700;
}

.actions {
  border-top: 2px solid var(--color-gray4);
  padding-top: 1.625rem;
  margin-right: 0.35rem;
  margin-top: 0.875rem;

  button {
    width: 10.12rem;
  }
}