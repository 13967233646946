.card {
  width: 100%;
  background-color: var(--color-whitegray);
  display: flex;
  border-radius: 0.375rem;
  // overflow: hidden;
  // height: 5.81rem;
  // min-height: 5.81rem;
  padding: .9rem 1rem;
  box-sizing: border-box;
}

.cardShort {
  border-radius: 0.2rem;
}

.closable {
  padding: .9rem 0;
}

.cardActive {
  height: unset;
}

.arrowField {
  width: 4.75rem;
  min-width: 4.75rem;
  display: flex;
  justify-content: center;
  // height: 100%;
  cursor: pointer;
}

.arrowFieldShort {
  width: 3rem;
  min-width: 3rem;
}

.arrow {
  margin-top: 0.55rem;
  width: 1rem;
  height: 0.57rem;
  background-image: url('../../../assets/images/arrowDown.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.arrowActive {
  // margin-top: 1.57rem;
  transform: rotateX(180deg);
  // filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(70deg) brightness(107%) contrast(101%);
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.size_s {
  font-size: 1.125rem;

  & .arrowField {
    width: 3rem;
    min-width: 3rem;
  }
}

.size_m {
  font-size: 1.375rem;
}

.actions {
  display: flex;
  align-items: center;
}

.filterIcon {
  background-image: url('../../../assets/images/filter.svg');
  background-size: contain;
  background-repeat: no-repeat; 
  height: 3.33rem;
  width: 3.875rem;
  min-width: 3.875rem;
  cursor: pointer;
  margin-right: 2.875rem;
}

.checked {
  background-color: var(--color-darkblue);

  & .arrow {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(70deg) brightness(107%) contrast(101%);
  }

  & * {
    color: white;
  }
}

.check > * {
  cursor: pointer;
}