.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox__inner {
  border: 1px solid var(--color-gray2);
  border-radius: 1px;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkbox__checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.checkbox__svg {
  width: 1.48rem;
  height: 1.48rem;
  object-position: center;
}

.checkbox__input {
  display: none;

  &:checked + .checkbox__checkmark {
    opacity: 1;
  }
}

.checkbox__title {
  margin-right: 1.62rem;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--color-gray2);
}

.disabled {
  opacity: 0.6;
  cursor: default;
  
  & > * {
    cursor: default;
  }
}


.dropdownRow__noted {
  display: none;
  width: 1.48rem;
  height: 1.48rem;
}

.dropdownRow__checkbox {
  width: 1.4rem;
  height: 1.4rem;
  border: 1px solid var(--color-gray2);
  border-radius: 1px;
  flex-shrink: 0;
}

.dropdownRow__svg {
  width: 100%;
  height: 100%;
}

.dropdownRow__noted_active {
  display: block;
  flex-shrink: 0;
}

.bigCheckbox {
  width: 1.68rem;
  height: 1.68rem;

  & > .dropdownRow__noted, & > .checkbox__svg {
    width: 1.78rem;
    height: 1.78rem;
  }
}