.root {
  flex: 1;
  height: 100%;
  overflow: hidden;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.8rem;
  background-color: var(--color-gray6);
  width: 100%;
  border-radius: 0.2rem;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.3rem 0.8rem;
  margin-bottom: 0.5rem;
}

.list {
  flex: 1;
  overflow: hidden auto;
}

.listTitle {
  background-color: var(--color-gray6);
  padding: 0.5rem 0.8rem;
  border-radius: 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.listItem {
  margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  justify-content: flex-end;

  & > button {
    font-size: 1rem;
    height: 3rem;
    width: 8rem;
  }
}

.filterIcon {
  background-image: url('../../../../assets/images/filter.svg');
  background-size: contain;
  background-repeat: no-repeat; 
  height: 2.2rem;
  width: 2.6rem;
  cursor: pointer;
}