.modal {
  width: 51.5rem;
  height: 43.75rem;

  & > div {
    padding: 1.875rem;
  }
}

.innerModal {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 1.375rem;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1.75rem;

  & > button {
    width: 10.125rem;
  }
}

.navbar {
  display: flex;
  align-items: center;
  margin-bottom: 1.91rem;
}

.navItem {
  height: 2.69rem;
  padding: 0 2.31rem;
  background-color: var(--color-whitegray);
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 700;
  margin-right: 1.56rem;
  cursor: pointer;
}

.activeNav {
  background-color:rgb(17, 202, 188);
  color: white;
  cursor: default;
}

.searchBar {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
  
}

.searchButton {
  width: 7.25rem;
  height: 2.5rem;
  border-radius: 0.375rem;
  font-weight: 700;
  font-size: 1.125rem;
}

.searchInput {
  display: block;
  height: 2.5rem;
  width: 23.75rem;
  background-color: white;
  outline: none;
  border-radius: 0.375rem;
  border: 1px solid #7F7F7F;
  margin-right: 0.81rem;
  padding: 0 .5rem;
}

.dropzone {
  display: flex;
  align-items: center;
}

.fileInput {
  width: 0;
  height: 0;
  z-index: -1;
  visibility: hidden;
}

.uploadButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  min-width: max-content;
  height: 3.62rem;
  color: var(--color-white);
  font-size: 1.25rem;
  font-weight: 800;
  text-align: center;
  border: none;
  border-radius: 1.75rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  background-color: var(--color-darkblue);
  width: 10.125rem;
}

.uploadButtonDisabled {
  background-color: var(--color-whitegray);
  color: var(--color-gray);
  border: none;
  cursor: default;
}

.copyIcon {
  height: 1.625rem;
  width: 1.44rem;
  background-image: url('../../../../assets/images/copy.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1rem;
  cursor: pointer;
}

.imageItem {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.imageWrapper {
  width: 8rem;
  height: 8rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2.25rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    display: block;
  }
}

.imageInfo {
  display: flex;
  align-items: center;
}

.iconImage {
  width: 1.7rem;
  height: 1.7rem;
}

.recycleIcon {
  width: 1.2rem;
  height: 1.4rem;
  background-image: url('../../../../assets/images/recycle.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 0.875rem;
  cursor: pointer;
}