.section {
  width: 100%;
  padding-bottom: 1.32rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 11rem);
}

.listBoard {
  width: 100%;
  display: flex;
  height: calc(100% - 4.31rem);

  & > div:not(:last-child) {
    margin-right: 1.43rem;
  }

  & > div {
    width: calc((100% - 2.86rem) / 3);
  }
}

.eventBoard {
  width: 100%;
  height: 3rem;
  border-radius: 0.375rem;
  border: 1px solid black;
  overflow: hidden;
  margin-top: 1.31rem;
  display: flex;
  align-items: center;
  font-size: 1.375rem;

  & > div:first-child {
    font-weight: 700;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: var(--color-gray6);
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.eventTypes {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 1.87rem;
}

.eventType {
  display: flex;
  align-items: center;
  margin-left: 4%;
  cursor: pointer;
  white-space: nowrap;

  & > div:first-child {
    margin-right: 0.68rem;
  } 
}

.disabled {
  opacity: 0.7 !important;
  cursor: default !important;
}

.typeCheckbox > div {
  background-color: white;
}