.progressBar {
  width: 100%;
  height: 100%;
  max-height: 2.5rem;
  min-height: 2rem;
  background-color: white;
  border: 2px solid var(--color-lightgreen);
  border-radius: 0.375rem;
  overflow: hidden;
}

.activeProgress {
  height: 100%;
  max-height: 2.5rem;
  min-height: 2rem;
  background-color: var(--color-lightgreen);
}