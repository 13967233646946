.heading {
  width: 100%;
  background-color: var(--color-whitegray);
  height: 7.125rem;
  min-height: 7.125rem;
  border-radius: 0 2.56rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.19rem;
  padding-right: 1.69rem;
  margin-bottom: 1.31rem;
}

.headingTitle {
  font-size: 2rem;
  font-weight: 700;
}

.actions {
  display: flex;
  align-items: center;
}

.moderatorReset {
  margin-right: 1rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  height: 3rem;
}