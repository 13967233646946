.content {
  font-size: 1.375rem;
}

.middleSide {
  display: flex;
}

.leftSide {
  width: 60%;
  padding-right: 1.125rem;
}

.rightSide {
  flex-grow: 1;
  padding-left: 1.93rem;
}


.filterRow {
  display: flex;
  margin-bottom: 0.85rem;

  & > div:first-child {
    font-weight: 700;
  }
}

.leftRow > div:first-child {
  width: 13.5rem;
  min-width: 13.5rem;
  margin-right: 3rem;
}

.rightRow > div:first-child {
  width: 8.75rem;
  min-width: 8.75rem;
  margin-right: 4.68rem;
}

.verticalLine {
  width: 2px;
  min-width: 2px;
  background-color: black;
  margin-bottom: 1.65rem;
}

.filterList {
  display: flex;
  flex-direction: column;
}

.filterItem {
  margin-bottom: 0.1rem;
}