.sidebar {
  height: 100%;
  width: 5.81rem;
  min-width: 5.81rem;
  background-color: var(--color-gray7);
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebarActive {
  width: 18rem;
  min-width: 18rem;
}

.sidebarItem {
  width: 100%;
  display: flex;
  align-items: center;
  height: 3.875rem;
  color: black;
  font-size: 1.31rem;
  cursor: pointer;
}

.activeItem {
  background-color: var(--color-lightgreen);
  color: white;
  cursor: default;
}

.sidebarIcon {
  width: 2.68rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;

  & > div {
    width: 1.68rem;
    height: 1.68rem;
  }
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.sidebarText {
  padding-left: 1.25rem;
  width: 11.37rem;
}

.sidebarArrow {
  cursor: pointer;
  padding-right: .5rem;
  width: 4.13rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.arrow {
  width: 1rem;
  height: 0.56rem;
  background-image: url('../../../../assets/images/arrowDown.svg');
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(70deg) brightness(107%) contrast(101%);
}

.rightArrow {
  transform: rotate(270deg);
}

.active {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(70deg) brightness(107%) contrast(101%);
}