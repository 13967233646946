.customerSatisfaction {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.satisfactionBoard {
  flex-grow: 1;
  width: 100%;
  display: flex;
  overflow-y: auto;
  margin-top: 1.94rem;
  padding-left: 2.93rem;
  margin-bottom: 1rem;
}

.satisfactionItem {
  width: 35%;
}

.satisfactionItem:not(:first-child) {
  margin-left: 6.18rem;
}

.noEvent {
  margin-top: 2rem;
  width: 100%;
}