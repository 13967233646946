.root {
  width: 30%;
  height: 100%;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-all;
}

.header {
  display: flex;
  background-color: var(--color-gray6);
  width: calc(100% - 3rem);
  height: 3.8rem;
  border-radius: 0.2rem;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.4rem 0;
  margin-right: 3rem;
  

  & > div {
    width: 50%;
    padding: 0.7rem 0.8rem;
  }

  & > div:last-child {
    border-left: 2px solid white;
  }

}

.list {
  flex: 1;
  overflow: hidden;
}

.listItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
}

.listItem:nth-child(even) > .listItemInfo {
  background-color: var(--color-white2);

}

.listItem:nth-child(odd) > .listItemInfo {
  background-color: var(--color-whitegray);

}

.listItemActive > .listItemInfo {
  background-color: var(--color-darkblue) !important;
  color: white;
  cursor: default;
}

.listItemInfo {
  display: flex;
  flex: 1;
  border-radius: 0.2rem;
  font-size: 1.125rem;

  cursor: pointer;
  & > div {
    width: 50%;
    padding: 0.7rem 0.8rem;
  }
}

.recycleWrapper {
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recycleIcon {
  width: 1.25rem;
  height: 1.35rem;
  background-image: url('../../../../assets/images/recycle.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.noModerator {
  text-align: center;
  font-weight: 700;
  padding: 0.7rem 0.8rem;
  margin: 0.4rem 3rem 0.4rem 0;
  border-radius: 0.2rem;
  background-color: var(--color-whitegray);
}