.section {
  width: 100%;
  padding-top: 1.32rem;
  display: flex;
  min-height: 18rem;

}

.dateBoard {
  width: 100%;
  height: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: flex-end;
  overflow: visible;

  & > div:not(:first-child) {
    margin-top: 1rem;
  }
}

.grayRow {
    height: 3rem;
    background-color: var(--color-gray6);
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    padding-left: 1.187rem;
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 0.5rem;
}

.datepickers {
  width: 100%;
  display: flex;
  margin-top: 1.75rem;
  position: relative;
}

.datepicker {
  width: calc((100% - 4.2rem) / 2)
}

.datepicker:first-child {
  margin-right: 2.94rem;
}

.datepickersBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
}

.datepickerWrapper {
  width: 100px;
}

.dateSwitcher {
  display: flex;
  font-size: 1.125rem;
  padding-top: 1.5rem;



  & > div:first-child {
    margin-right: 2rem;
  }
}

.dateSwitchItem {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  & > div:first-child {
    margin-right: 0.6rem;
  }
}

.activeDateState {
  cursor: default;
  & * {
    cursor: default;
  }
}

.dissable {
  opacity: 0.6;
  cursor: default;
  & * {
    cursor: default;
  }
}