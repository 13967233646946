.background {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(229, 229, 229, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  height: calc(100vh - 4.68rem);
  width: calc(100% - 4.125rem);
  // margin-top: 1.81rem;
  z-index: 11;
  border-radius: 1.56rem;
  // margin-left: 2.93rem;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 2px solid #B7B7B7;
  padding: 3.375rem 4.375rem 1.875rem 2.625rem;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow: hidden;
  display: flex;
  user-select: none;
}

.title {
  font-size: 1.375rem;
  font-weight: 700;
}

.leftSection {
  height: 100%;
  // width: 50%;
  padding-right: 3.68rem;
  display: flex;
  flex-direction: column;
}

.rightSection {
  height: 100%;
  flex-grow: 1;
  padding-left: 3.68rem;
  display: flex;
  flex-direction: column;
}

.leftRightToggle {
  width: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}

.leftRightToggle__inner {
  width: 2px;
  height: 100%;
  min-width: 2px;
  background-color: #ccc;
}

.section2 {
  width: 100%;
  flex-grow: 1;
  padding-top: 1.32rem;
  display: flex;
  min-height: 18rem;

}

.dateBoard {
  height: 100%;
  width: 20.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  & > div:not(:first-child) {
    margin-top: 1rem;
  }
}

.calendarBoard {
  height: 100%;
  width: calc(100% - 20.3rem);
  // background-color: red;
}

.grayRow {
    height: 3rem;
    background-color: var(--color-gray6);
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    padding-left: 1.187rem;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.topBottomtoggle {
  width: 93%;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: grab;
}

.topBottomtoggle__inner {
  width: 100%;
  height: 2px;
  min-height: 2px;
  background-color: #ccc;
}

.listItem {
  width: 50%;
}

.hidden {
  display: none;
}