.root {
  width: 3.06rem;
  height: 3.06rem;
  border: 1px solid #1C3664;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.icon {
  background-image: url('../../assets/images/sortArrow.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.1rem;
  height: 1.4rem;
}

.desc {
  transform: rotateX(180deg);
}

.disabled {
  opacity: 0.5;
  cursor: default;
}