.futureRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cards {
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 3.93rem;
  padding-right: 1.43rem;
}

.addButton {
  border-radius: 0.375rem;
  height: 3.06rem;
  font-size: 1.125rem;
  font-weight: 700;
  width: 9.81rem;
}

.headingActions {
  display: flex;
  align-items: center;
}

.headingItem {
  margin-left: 1.25rem;
}